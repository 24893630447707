import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/LoadSpinner.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiCircularProgress: {
      colorPrimary: {
        color: '#2fabe1',
      },
    },
  },
});

const LoadSpinner = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&
    <div className="spinner">
      <ThemeProvider theme={theme}>
        <CircularProgress
          color="primary"
          size={100} />
      </ThemeProvider>
    </div>
  );
}

export default LoadSpinner;
