import React from 'react';
import '../styles/Header.css'
import PicFixLogo from "../images/PicFix_Color logo.png";
import { Navbar } from 'react-bootstrap';

class Header extends React.Component {

  render() {
    return (
      <Navbar expand="lg" className="header" sticky="top">
        <img className="picfix-logo" alt="PicFix" src={PicFixLogo} width="135" height="90" />
      </Navbar>
    );
  }
}

export default Header;
