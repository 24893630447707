/*
Author: Marshall Purdom
This React frontend connects with a Python flask backend.
*/

import React from 'react';
import Header from "./Header";
import Footer from "./Footer"
import ClaimsForm from "./ClaimsForm";
import Confirmation from "./Confirmation";
import Error from "./Error";
import '../styles/App.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

function App() {
  const location = useLocation()
  return (
    <div className="page">
      <Header />
      <TransitionGroup>
        <CSSTransition
          timeout={300}
          classNames='fade'
          key={location.key}
        >
          <Switch location={location}>
            <Route exact path="/" component={ClaimsForm} />
            <Route exact path="/confirmation" component={Confirmation} />
            <Route exact path="/error" component={Error} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div >
  );
}

export default App;
