import React from 'react';
import '../styles/Footer.css'
import PoweredByLogo from "../images/Powered_by_Nobilis_blu_wh_small.png";
import { Navbar } from 'react-bootstrap';

class Footer extends React.Component {

  render() {
    return (
      <div>
        <Navbar expand="lg" className="footer">
          <img className="powered-by" alt="PicFix" src={PoweredByLogo} width="161" height="50" />
        </Navbar>
        <div className="footer2">
          © Nobilis Group, Inc. All rights reserved.
        </div>
      </div>
    );
  }
}

export default Footer;
