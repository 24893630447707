import React from 'react';
import '../styles/Confirmation.css'
import { withRouter } from 'react-router-dom';

class Confirmation extends React.Component {

  render() {
    return (
      <div className="confirmation">
        <div className="confirmation-box">
          <span className="text-overflow-center">
            <h1>
              Thanks!
            </h1>
          </span>
          <br />
          <span className="text-overflow-center">
            <h2>
              Your damage appraisal request has been submitted. One of our adjusters will be in touch with you soon regarding the next steps with your claim.
            </h2>
          </span>
        </div>
      </div >
    );
  }
}

export default withRouter(Confirmation);
